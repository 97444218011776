import React from 'react'
import { Link, navigate } from 'gatsby'
import {
  Container,
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
} from 'reactstrap'

import useToggle from '../hooks/useToggle'

import Logo from '../svg/logo.svg'

import './Navbar.scss'

const routes = [
  {
    label: 'Leistungen',
    id: '#services',
  },
  {
    label: 'Team',
    id: '#team',
  },
  {
    label: 'Über Uns',
    id: '#about',
  },
  {
    label: 'Kontakt',
    id: '#contact',
  },
]

const NavLink = ({ children, to, className = '', ...props }: NavLinkProps) => {
  return (
    <Link to={to} className={`nav-link ${className}`} {...props}>
      {children}
    </Link>
  )
}

interface NavLinkProps {
  to: string
  children: React.ReactNode
  className?: string
  onClick?: (e: any) => void
}

const Navigation = () => {
  const [isOpen, toggleIsOpen] = useToggle()

  const scrollToId = (id: string) => {
    const element = document.querySelector(id)
    const topOffset = element.getBoundingClientRect().top + window.pageYOffset

    window.scrollTo({
      left: 0,
      top: topOffset - 50,
      behavior: 'smooth',
    })
  }

  const handleNavClick = (e, id?: string) => {
    const isCurrentlyOnHomePage = window.location.pathname === '/'
    // Scroll to section when on home page
    if (id) {
      e.preventDefault()
      if (isCurrentlyOnHomePage) {
        scrollToId(id)
        //window.location.hash = id.replace('#', '')
      } else navigate(`/${id}`)
    }

    if (isOpen) toggleIsOpen()
  }

  return (
    <Navbar dark expand="md" fixed="top">
      <Container>
        <NavLink to="/" className="navbar-brand">
          <Logo />
        </NavLink>
        <NavbarToggler onClick={toggleIsOpen} />

        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            {routes.map(({ id, label }) => (
              <NavItem key={id}>
                <NavLink
                  onClick={e => handleNavClick(e, id)}
                  to={id}
                  className="nav-link"
                >
                  {label}
                </NavLink>
              </NavItem>
            ))}
            <NavItem>
              <NavLink
                to={'/karriere'}
                onClick={e => handleNavClick(e)}
                className="nav-link"
              >
                Karriere
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  )
}

export default Navigation
