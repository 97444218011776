import React from 'react'
import { Link } from 'gatsby'
import { Container, Row, Col } from 'reactstrap'

import './Footer.scss'

const Footer = () => {
  return (
    <footer className="Footer">
      <Container>
        <Row>
          <Col>
            <p>
              &copy; KiPA Nachrichtentechnik {new Date().getFullYear()}
              <br />
              <Link to="/impressum">Impressum</Link> |{' '}
              <Link to="/datenschutz">Datenschutz</Link>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
