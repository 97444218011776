import React from 'react'
import { CloudinaryContext } from 'cloudinary-react'
//import CookieBanner from 'react-cookie-banner'

import { SEODefaults } from '../components/SEO'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
// import CoronaBanner from '../components/CoronaBanner'

// // Smooth scroll polyfill for ancient browsers
// import smoothscroll from 'smoothscroll-polyfill'
// smoothscroll.polyfill()

import '../styles/global.scss'

const { GATSBY_CLOUDINARY_CLOUD_NAME } = process.env

const Layout = ({ children }: Props) => {
  return (
    <CloudinaryContext cloudName={GATSBY_CLOUDINARY_CLOUD_NAME || 'kipa'}>
      <SEODefaults />
      {/* <CoronaBanner /> */}
      {/* <CookieBanner
        message="Diese Website verwendet Cookies."
        cookie="user-has-accepted-cookies"
        buttonMessage="OK"
      /> */}
      <Navbar></Navbar>
      <main>{children}</main>
      <Footer />
    </CloudinaryContext>
  )
}

interface Props {
  children: React.ReactChildren | string
}

export default Layout
